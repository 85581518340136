body {
  margin: 0;
  font-family: "monofonto", "Lucida Console", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: black;
  color: white;
  -webkit-text-stroke-width: 8px;
  -webkit-text-stroke-color: black;
  paint-order: stroke fill;
  overflow: hidden;
  font-size: large;
  font-weight: bold;
  scrollbar-color: #ed1c24 black;
  scrollbar-width: thin;
}

@font-face {
  font-family: "monofonto";
  src: url(../public/monofonto.otf) format("opentype");
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-pinned {
  position: absolute;
  z-index: 1;
  background-color: #020006 !important;
}

.header-unpinned {
  background-color: #02000680 !important;
}

a {
  color: #ed1c24 !important;
}

a:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

a:visited {
  color: #ed1c24 !important;
  -webkit-text-stroke-width: 8px;
  -webkit-text-stroke-color: black;
}

button:focus {
  outline: #ed1c24 solid 2px;
}

button:focus:not(:focus-visible) {
  outline: none;
}

div:focus {
  outline: #ed1c24 solid 2px;
}

div:focus:not(:focus-visible) {
  outline: none;
}

a:focus {
  outline: #ed1c24 solid 2px;
}

a:focus:not(:focus-visible) {
  outline: none;
}

.spin {
  animation: rotate 0.5s forwards;
}

@keyframes rotate {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 180deg;
  }
}

.unspin {
  animation: unrotate 0.5s forwards;
}

@keyframes unrotate {
  from {
    rotate: 180deg;
  }

  to {
    rotate: 0deg;
  }
}

.trans-sidebar {
  transition: min-width 0.5s;
}

.slide-in {
  animation: slidein 0.25s both 0.5s;
}

@keyframes slidein {
  from {
    transform: translateX(-20px);
    opacity: 0%;
  }

  to {
    transform: translateX(0px);
    opacity: 100%;
  }
}

.drop-in {
  animation: dropin 0.25s both 0.25s;
}

@keyframes dropin {
  from {
    transform: translateY(-20px);
    opacity: 0%;
  }

  to {
    transform: translateY(0px);
    opacity: 100%;
  }
}

.to-red:hover {
  animation: tored 0.1s both;
}

@keyframes tored {
  from {
    color: white
  }

  to {
    color: #ed1c24
  }
}
